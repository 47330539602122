<template>
  <div>
    <k-crud-page>
      <template #condition>
        <k-text-input
          v-model="cruddataMX.conditions.searchParam"
          class="my-1"
          label="机构信息"
        />
        <k-primary-button
          class="my-1 mr-4"
          text="搜索"
          icon="mdi-magnify"
          @click="crudConditionsMX().resetPage()"
        />
        <!--                    <k-cancel-button-->
        <!--                      class="my-1 mr-4"-->
        <!--                      text="重置"-->
        <!--                      icon="mdi-restore"-->
        <!--                      @click="crudConditionsMX().reset()"-->
        <!--                    />-->
      </template>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :total="cruddataMX.pagination.total"
          :value="cruddataMX.pagination.current"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #healthStatusName="{ item }">
            <v-chip v-if="item.bankOutlets === '01'" color="success">
              是
            </v-chip>
            <v-chip v-else color="info"> 否 </v-chip>
          </template>
          <template #roleVos="{ item }">
            {{
              `(${item.longitude !== null ? item.longitude : '--'}, ${
                item.latitude !== null ? item.latitude : '--'
              })`
            }}
          </template>
          <template #top>
            <v-toolbar flat dense>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                v-if="hasPermission(['organization.add'])"
                class="mr-2"
                icon="mdi-plus"
                text="新增机构"
                @click="
                  crudActionMX().newItem(
                    crud.default,
                    onOpenEdit(crud.default, '01')
                  ),
                    (networkShowFlag = false)
                "
              />
              <!--              <k-warning-btn-->
              <!--                text="导入"-->
              <!--                icon="mdi-logout-variant"-->
              <!--                class="mx-2"-->
              <!--              />-->
              <c-upload
                v-if="hasPermission(['organization.import'])"
                :api="uploadExcel"
                btn-type="warning"
                btn-icon="mdi-logout-variant"
                upload-text="导入"
                accept=".xls,.xlsx"
                upload-type="btn"
                class="mr-2"
              ></c-upload>
              <k-secondary-btn
                v-if="hasPermission(['organization.export'])"
                class="mr-2"
                text="导出"
                icon="mdi-login-variant"
                @click="exportsExcel"
              />
              <k-secondary-btn
                v-if="hasPermission(['organization.import'])"
                class="mr-2"
                text="下载模板"
                icon="mdi-login-variant"
                @click="exportsExcelTemp"
              />
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                maxwidth="950px"
                :actionshow="actionShow"
                @save="
                  () => {
                    crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref">
                    <v-row no-gutters class="">
                      <v-col :cols="6">
                        <k-text-input
                          v-model="cruddataMX.data.orgCode"
                          placeholder="请输入机构编码"
                          label="机构编码"
                          :rules="[globalRules.empty]"
                          :disabled="editFlag"
                        />
                      </v-col>
                      <v-col :cols="6">
                        <k-tree-select
                          v-model="cruddataMX.data.parentCode"
                          placeholder="请选择上级机构"
                          label="上级机构"
                          :items="items"
                          :disabled="editFlag"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="">
                      <v-col :cols="6">
                        <k-text-input
                          v-model="cruddataMX.data.orgName"
                          placeholder="请输入机构名称"
                          label="机构名称"
                          :rules="[globalRules.empty]"
                          :disabled="editFlag"
                        />
                      </v-col>
                      <v-col :cols="6">
                        <k-text-input
                          v-model="cruddataMX.data.orgShortName"
                          placeholder="请输入机构简称"
                          label="机构简称"
                          :rules="[globalRules.empty]"
                          :disabled="editFlag"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="">
                      <v-col :cols="6">
                        <k-text-input
                          v-model="cruddataMX.data.telNo"
                          placeholder="请输入联系电话"
                          label="联系电话"
                          :rules="
                            cruddataMX.data.telNo ? [globalRules.phoneNew] : []
                          "
                          :disabled="editFlag"
                        />
                      </v-col>
                      <v-col
                        style="display: flex; align-items: center"
                        :cols="6"
                      >
                        <v-row>
                          <v-col :cols="6">
                            <k-text-input
                              v-model="cruddataMX.data.longitude"
                              label-width="80px"
                              placeholder="请输入机构经度"
                              label="经度"
                              type="number"
                              :disabled="editFlag"
                            />
                          </v-col>
                          <v-col :cols="6">
                            <k-text-input
                              v-model="cruddataMX.data.latitude"
                              label-width="80px"
                              placeholder="请输入机构维度"
                              label="维度"
                              type="number"
                              :disabled="editFlag"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--                      <v-col :cols="3">-->
                      <!--                        -->
                      <!--                      </v-col>-->
                    </v-row>
                    <k-text-input
                      v-model="cruddataMX.data.address"
                      placeholder="请输入机构地址"
                      label="地址"
                      max-length="70"
                      class=""
                      :disabled="editFlag"
                    />
                    <v-row no-gutters class="">
                      <k-checkbox
                        v-model="cruddataMX.data.bankOutlets"
                        class="pl-3"
                        label="网点"
                        :disabled="editFlag"
                        @change="bankOutletsCheck"
                      />
                    </v-row>
                    <v-card
                      v-if="cruddataMX.data.bankOutlets"
                      class="ma-4 pa-7"
                      color="#f5f6fa"
                      rounded="rounded-lg"
                      flat
                    >
                      <v-row>
                        <v-col :cols="6">
                          <div class="d-flex">
                            <span class="mr-3">是否有金库</span>
                            <k-radio-group
                              v-model="cruddataMX.data.treasuryFlg"
                              :disabled="editFlag"
                              :items="treasuryList"
                              label=""
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <div class="my-3">高柜柜台:</div>
                      <div class="d-flex mt-5">
                        <k-crud-table
                          v-if="crud.switch"
                          :elevation="false"
                          class="flex-grow-1"
                          :headers="columns1"
                          :value="cruddataMX.pagination.current"
                          :items="cruddataMX.data.counterList"
                          :paginationshow="false"
                        >
                          <template #actions="{ item, index }">
                            <k-primary-button
                              icon="mdi-delete"
                              type="error"
                              :disabled="editFlag"
                              :mall="true"
                              :isicon="true"
                              @click="delCounter(item, index)"
                            />
                          </template>
                          <template #createdTime="{ item }">
                            <k-text-input
                              v-model="item.code"
                              placeholder="请输入柜台号"
                              input-width="100px"
                              :rules="[
                                globalRules.empty,
                                globalRules.repeatTest(
                                  item.code,
                                  'code',
                                  cruddataMX.data.counterList
                                ),
                              ]"
                              :disabled="editFlag"
                            />
                          </template>
                          <template #roleVos="{ item }">
                            <k-text-input
                              v-model="item.ipAddr"
                              placeholder="请输入IP地址"
                              input-width="100px"
                              :rules="[
                                globalRules.ip,
                                globalRules.repeatTest(
                                  item.ipAddr,
                                  'ipAddr',
                                  cruddataMX.data.counterList
                                ),
                              ]"
                              :disabled="editFlag"
                            />
                          </template>
                          <template #[`append`]>
                            <tr class>
                              <td class="text-center info--text">柜台号</td>
                              <td class="text-center info--text">IP地址</td>
                              <td class="text-center">
                                <k-primary-button
                                  icon="mdi-plus"
                                  :disabled="editFlag"
                                  :mall="true"
                                  @click="addCounter"
                                />
                              </td>
                            </tr>
                          </template>
                        </k-crud-table>
                      </div>
                      <div class="my-3">自助机具:</div>
                      <div class="d-flex mt-5">
                        <k-crud-table
                          v-if="crud.switch"
                          :elevation="false"
                          class="flex-grow-1"
                          :headers="columns2"
                          :items="cruddataMX.data.toolList"
                          :value="cruddataMX.pagination.current"
                          :paginationshow="false"
                        >
                          <template #actions="{ item }">
                            <k-primary-button
                              icon="mdi-delete"
                              type="error"
                              :disabled="editFlag"
                              :mall="true"
                              :isicon="true"
                              @click="delTool(item)"
                            />
                          </template>
                          <template #createdTime="{ item }">
                            <k-text-input
                              v-model="item.code"
                              placeholder="请输入编号"
                              input-width="100px"
                              :disabled="editFlag"
                              :rules="[
                                globalRules.empty,
                                globalRules.repeatTest(
                                  item.code,
                                  'code',
                                  cruddataMX.data.toolList
                                ),
                              ]"
                            />
                          </template>
                          <template #roleVos="{ item }">
                            <k-text-input
                              v-model="item.name"
                              placeholder="请输入名字"
                              :rules="[globalRules.empty]"
                              input-width="100px"
                              :disabled="editFlag"
                            />
                          </template>
                          <template #roleVos1="{ item }">
                            <k-select
                              v-model="item.areaUuid"
                              :rules="[globalRules.empty]"
                              :items="areaList"
                              input-width="130px"
                              :disabled="editFlag"
                            />
                          </template>
                          <template #[`append`]>
                            <tr class>
                              <td class="text-center info--text">编号</td>
                              <td class="text-center info--text">名称</td>
                              <td class="text-center info--text">位置</td>
                              <td class="text-center">
                                <k-primary-button
                                  icon="mdi-plus"
                                  class="ml-3"
                                  :disabled="editFlag"
                                  :mall="true"
                                  @click="addTool"
                                />
                              </td>
                            </tr>
                          </template>
                        </k-crud-table>
                      </div>
                    </v-card>
                    <!--                    </div>-->
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-primary-btn
              v-if="hasPermission(['organization.view'])"
              text="查看"
              @click="
                crudActionMX().details(
                  crud,
                  item.uuid,
                  onOpenEdit(item),
                  formatData
                )
              "
            />
            <k-table-warning-btn
              v-if="hasPermission(['organization.edit'])"
              text="编辑"
              class="mx-4"
              @click="
                crudActionMX().editItem(item, () => onOpenEdit(item, '01'))
              "
            />
            <k-table-error-btn
              v-if="hasPermission(['organization.delete'])"
              text="删除"
              @click="crudActionMX().deleteItem(item)"
            />
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import { easyPoiExcel } from '@/api/exportsExcel';
import { uploadExcel } from '@/api/upload';
export default {
  data() {
    return {
      uploadExcel,
      crud: {
        name: '机构',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        // select: {
        //   roles: {
        //     loading: false,
        //     current: 1,
        //     defaultPageSize: 10,
        //     data: [],
        //   },
        // },
        default: {
          uuid: null,
          orgCode: '',
          orgName: '',
          orgShortName: '',
          parentCode: [],
          telNo: '',
          longitude: null,
          latitude: null,
          address: '',
          bankOutlets: false,
          treasuryFlg: '',
          status: '',
          counterList: [],
          toolList: [],
        },
      },
      columns: [
        {
          text: '序号',
          value: 'indexNum',
          sortable: false,
          align: 'center',
          class: 'vtableH',
        },
        {
          text: '机构编号',
          value: 'orgCode',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'orgName',
          sortable: false,
          align: 'center',
        },
        {
          text: '简称',
          value: 'orgShortName',
          sortable: false,
          align: 'center',
        },
        {
          text: '上级机构',
          value: 'parentOrganizationName',
          sortable: false,
          align: 'center',
        },
        { text: '电话', value: 'telNo', sortable: false, align: 'center' },
        { text: '地址', value: 'address', sortable: false, align: 'center' },
        {
          text: '经纬度',
          value: 'roleVos',
          sortable: false,
          align: 'center',
        },
        {
          text: '是否为网点',
          value: 'healthStatusName',
          sortable: false,
          align: 'center',
        },
        { text: '操作', value: 'actions', sortable: false, align: 'center' },
      ],
      columns1: [
        {
          text: '柜台号',
          value: 'createdTime',
          height: '100px',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '终端Ip',
          value: 'roleVos',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '操作',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
      ],
      columns2: [
        {
          text: '编号',
          value: 'createdTime',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '名称',
          value: 'roleVos',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '位置',
          value: 'roleVos1',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '操作',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
      ],
      rules: {
        required: (value) => !!value || '不能为空',
        phone: (v) => {
          if (v) {
            return /^(1)\d{10}$/.test(v) || '手机号码格式不正确！';
          }
        },
        ip: (v) =>
          /((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/.test(
            v
          ) || 'IP地址不规范',
      },
      // 网点面板显示Flg
      networkShowFlag: false,
      actionShow: true,
      editFlag: false,
      areaList: [],
      treasuryList: [
        { value: '01', label: '是' },
        { value: '02', label: '否' },
      ],
      // 测试机构
      items: [],
      dict: [], //数据字典
    };
  },
  computed: {},
  created() {
    this.getSelectInit();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.organization.organization.getOrganization,
        add: (params) => {
          // eslint-disable-next-line no-debugger
          debugger;
          params.parentCode = params.parentCode.toString();
          params.bankOutlets = params.bankOutlets ? '01' : '02';
          return this.$api.organization.organization.addOrganization(params);
        },
        edit: (params) => {
          params.parentCode = params.parentCode.toString();
          params.bankOutlets = params.bankOutlets ? '01' : '02';
          return this.$api.organization.organization.editOrganization(params);
        },
        delete: (params) => {
          return this.$api.organization.organization.deleteOrganization({
            uuid: params.uuid,
            orgCode: params.orgCode,
          });
        },
        details: this.$api.organization.organization.getOrganizationDetail,
      },
      {},
      {
        // add: ['name', 'sign'],
        edit: [
          'uuid',
          'orgCode',
          'orgName',
          'orgShortName',
          'parentCode',
          'telNo',
          'longitude',
          'latitude',
          'address',
          'bankOutlets',
          'treasuryFlg',
          'status',
          'counterList',
          'toolList',
        ],
      }
    );
  },
  methods: {
    Export(e) {
      console.log(e);
    },
    exportsExcel() {
      easyPoiExcel(
        '/fds/organization/export-excel',
        '机构列表',
        this.crudConditionsMX().get()
      );
    },
    exportsExcelTemp() {
      easyPoiExcel(
        '/fds/organization/excel-template',
        '机构列表',
        this.crudConditionsMX().get()
      );
    },
    formatData(resData) {
      if (resData) {
        resData.parentCode = [resData.parentCode];
        resData.bankOutlets = resData.bankOutlets === '01';
        // this.crud.default.counterList = resData.counterList;
        resData.counterList = resData.counterList || [];
        // this.crud.default.toolList = resData.toolList;
        resData.toolList = resData.toolList || [];
      }
      return resData;
    },
    // 添加柜台
    addCounter() {
      const param = { code: '', ipAddr: '' };
      // const list = this.cruddataMX.data.counterList;
      // if (list.length === 0) {
      //   this.cruddataMX.data.counterList.push(param);
      // } else {
      //   let tempIndex = list.length - 1;
      //   for (let index = 0; index < list.length; index++) {
      //     const element = list[index];
      //     if (element.code === list[tempIndex].code && index !== tempIndex) {
      //       this.$alert.warning('柜台编号重复,请重新输入');
      //       return;
      //     }
      //   }
      this.cruddataMX.data.counterList.push(param);
      // }
    },
    // 删除柜台
    delCounter(item, index) {
      // let deleteIndex = this.cruddataMX.data.counterList.indexOf(item);
      this.cruddataMX.data.counterList.splice(index, 1);
    },
    // 添加机具
    addTool() {
      let param = {
        code: '',
        name: '',
        areaUuid: '',
      };
      // if (this.cruddataMX.data.toolList.length === 0) {
      //   this.cruddataMX.data.toolList.push(param);
      // } else {
      //   let tempIndex = this.cruddataMX.data.toolList.length - 1;
      //   if (
      //     this.cruddataMX.data.toolList[tempIndex].code === '' ||
      //     this.cruddataMX.data.toolList[tempIndex].name === '' ||
      //     this.cruddataMX.data.toolList[tempIndex].areaUuid === ''
      //   ) {
      //     this.$alert.warning('请先填写未填完的记录');
      //     return;
      //   }
      //   for (
      //     let index = 0;
      //     index < this.cruddataMX.data.toolList.length;
      //     index++
      //   ) {
      //     const element = this.cruddataMX.data.toolList[index];
      //     if (
      //       element.code === this.cruddataMX.data.toolList[tempIndex].code &&
      //       index !== tempIndex
      //     ) {
      //       this.$alert.warning('编号重复,请重新输入');
      //       return;
      //     }
      //   }
      this.cruddataMX.data.toolList.push(param);
      // }
    },
    // 删除机具
    delTool(item) {
      let deleteIndex = this.cruddataMX.data.toolList.indexOf(item);
      this.cruddataMX.data.toolList.splice(deleteIndex, 1);
    },
    // 编辑&查看
    async onOpenEdit(item, flg) {
      if (flg === '01') {
        this.actionShow = true;
        this.editFlag = false;
      } else {
        this.actionShow = false;
        this.editFlag = true;
      }
      if (item.uuid !== '' && item.uuid !== null) {
        await this.$api.organization.organization
          .getOrganizationDetail({
            uuid: item.uuid,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$set(this.cruddataMX, 'data', this.formatData(res.data[0]));
              console.log('parentCode---', this.cruddataMX.data.parentCode);
              // eslint-disable-next-line no-debugger
              // debugger;
              // this.$set(
              //   this.cruddataMX.data,
              //   'counterList',
              //   this.formatData(res.data[0]).counterList
              // );
              // this.$set(
              //   this.cruddataMX.data,
              //   'toolList',
              //   this.formatData(res.data[0]).toolList
              // );
            }
          });
      }
      this.getCommonData(item);
    },
    bankOutletsCheck() {
      this.networkShowFlag = !this.networkShowFlag;
    },
    // 共通数据获取方法
    getCommonData(item) {
      // this.crud.default.counterList = [];
      // this.crud.default.toolList = [];
      if (item.bankOutlets === null || item.bankOutlets === '') {
        this.networkShowFlag = false;
      } else {
        this.networkShowFlag = item.bankOutlets;
      }
      this.getTree(item);
      this.getDict();
    },
    getDict() {
      this.$api.organization.organization.getEnum().then(({ code, data }) => {
        if (code === 200) {
          this.dict = data;
        }
      });
    },
    getTree(item) {
      this.$api.organization.organization
        .getOrTree({ orgCode: item.orgCode })
        .then(({ code, data }) => {
          if (code === 200) {
            this.items = data;
          }
        });
    },
    // 初期下拉框值获取
    getSelectInit() {
      this.$api.organization.organization.getSelectInit().then((res) => {
        if (res.code === 200) {
          this.areaList = res.data;
        }
      });
    },
    // 重要信息重复验证
    checkRepeat(flg, item) {
      var currentIndex;
      if (flg === 1) {
        currentIndex = this.cruddataMX.data.counterList.indexOf(item);
        for (
          let index = 0;
          index < this.cruddataMX.data.counterList.length;
          index++
        ) {
          const element = this.cruddataMX.data.counterList[index];
          if (element.code === item.code && currentIndex !== index) {
            this.$alert.warning('柜台编号重复,请重新输入!');
            item.code = '';
          }
        }
      } else {
        currentIndex = this.cruddataMX.data.toolList.indexOf(item);
        for (
          let index = 0;
          index < this.cruddataMX.data.toolList.length;
          index++
        ) {
          const element = this.cruddataMX.data.counterList[index];
          if (element.code === item.code && currentIndex !== index) {
            this.$alert.warning('编号重复,请重新输入!');
            item.code = '';
          }
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
